import React, { useEffect } from "react"
import { useQueryParam, StringParam } from "use-query-params"
import ReactPlayer from "react-player"
import WaveSurfer from "wavesurfer.js"
import { FaDownload, FaRegFilePdf } from "react-icons/fa"
import { AiOutlineFileZip, AiOutlineFileUnknown } from "react-icons/ai"

import SEO from "../components/seo";
import BlackboxLogo from "../images/6blackbox.png"
import AudioPlayer from "./AudioPlayer"

function getFilePathExtension(path) {
  var filename = path
    .split("\\")
    .pop()
    .split("/")
    .pop()
  return filename.substr(
    (Math.max(0, filename.lastIndexOf(".")) || Infinity) + 1
  )
}

const PreviewPopUp = () => {
  const [previewUri, setPreviewUri] = useQueryParam("preview", StringParam)

  if (!previewUri) {
    return <div></div>
  }

  const filename = previewUri
    .split("/")
    .slice(-1)
    .pop()

  const fileExtension = getFilePathExtension(previewUri)

  console.log(fileExtension)

  let view = null

  switch (fileExtension) {
    case "mp3":
    case "wav":
      view = (
        <AudioPlayer
          url={`https://6lackbox-prod.s3.amazonaws.com/${previewUri}`}
          className="flex-1"
        />
      )
      break

    case "mp4":
    case "mov":
      view = (
        <div className="relative flex flex-col items-center justify-center flex-1 w-full h-full">
          <div className="flex flex-col items-center justify-center w-full max-w-6xl max-h-full p-6 bg-gray-200 rounded">
            <ReactPlayer
              url={`https://6lackbox-prod.s3.amazonaws.com/${previewUri}`}
              className="react-player"
              width={"100%"}
              height={"auto"}
              controls
              playing
            />
          </div>
        </div>
      )
      break

    case "jpeg":
    case "jpg":
    case "gif":
    case "png":
      view = (
        <div className="relative flex-1 w-screen h-screen p-4 pt-16 ">
          <img
            className="object-contain object-center w-full h-full"
            src={`https://6lackbox-prod.s3.amazonaws.com/${previewUri}`}
          />{" "}
        </div>
      )
      break

    case "pdf":
      view = (
        <div className="relative flex flex-col items-center justify-center flex-1 w-screen h-screen">
          <div className="flex flex-col items-center justify-center px-16 py-12 bg-gray-200 rounded md:px-32 md:py-20">
            <FaRegFilePdf className="w-16 h-auto mb-6 ml-3 text-theme-mid" />
            <h4 className="mb-6 text-theme-light">{filename}</h4>
            <a
              className="flex items-center justify-center px-3 py-2 text-base rounded bg-theme-mid text-theme-offwhite hover:bg-theme-dark"
              download
              href={`https://6lackbox-prod.s3.amazonaws.com/${previewUri}`}
            >
              <span>Download</span> <FaDownload className="w-4 h-5 ml-3" />
            </a>
          </div>
        </div>
      )
      break

    case "zip":
      view = (
        <div className="relative flex flex-col items-center justify-center flex-1 w-screen h-screen">
          <div className="flex flex-col items-center justify-center px-16 py-12 bg-gray-200 rounded md:px-32 md:py-20">
            <AiOutlineFileZip className="w-16 h-auto mb-6 ml-3 text-theme-mid" />
            <h4 className="mb-6 text-theme-light">{filename}</h4>
            <a
              className="flex items-center justify-center px-3 py-2 text-base rounded bg-theme-mid text-theme-offwhite hover:bg-theme-dark"
              download
              href={`https://6lackbox-prod.s3.amazonaws.com/${previewUri}`}
            >
              <span>Download</span> <FaDownload className="w-4 h-5 ml-3" />
            </a>
          </div>
        </div>
      )
      break

    default:
      view = (
        <div className="relative flex flex-col items-center justify-center flex-1 w-screen h-screen">
          <div className="flex flex-col items-center justify-center px-16 py-12 bg-gray-200 rounded md:px-32 md:py-20">
            <AiOutlineFileUnknown className="w-16 h-auto mb-6 ml-3 text-theme-mid" />
            <h4 className="mb-6 text-theme-light">{filename}</h4>
            <a
              className="flex items-center justify-center px-3 py-2 text-base rounded bg-theme-mid text-theme-offwhite hover:bg-theme-dark"
              download
              href={`https://6lackbox-prod.s3.amazonaws.com/${previewUri}`}
            >
              <span>Download</span> <FaDownload className="w-4 h-5 ml-3" />
            </a>
          </div>
        </div>
      )
  }

  const resetAndClose = () => {
    view = null
    setPreviewUri("")
  }

  return (
    <div
      className={`flex flex-col absolute inset-0 z-40 bg-gray-300 transition-opacity transition-faster spinner ${
        previewUri
          ? "opacity-100 pointer-events-auto ease-in"
          : "opacity-0 pointer-events-none ease-out"
      }`}
    >
    <SEO title={`Preview - ${filename}`} />
      {true && (
        <div className="relative flex flex-1 overflow-x-hidden overflow-y-scroll">
          {view}
        </div>
      )}

      <header className="absolute top-0 z-50 flex items-center justify-between w-full p-4 ">
        <img src={BlackboxLogo} className="w-10 h-10" />
        <span className="mx-3 text-base truncate text-theme-darker">{filename}</span>
        <button
          className="cursor-pointer hover:opacity-75"
          onClick={() => resetAndClose()}
        >
          <svg
            className="fill-current text-theme-dark"
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 18 18"
          >
            <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
          </svg>
        </button>
      </header>
    </div>
  )
}

export default PreviewPopUp
