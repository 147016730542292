import React from "react"
import { useQueryParam, StringParam } from "use-query-params"
import { useClipboard } from "use-clipboard-copy"
import { Twitter, Facebook, Whatsapp } from "react-social-sharing"

const SharePopUp = ({ location }) => {
  const [shareUri, setShareUri] = useQueryParam("share", StringParam)
  const clipboard = useClipboard({
    copiedTimeout: 1000, // timeout duration in milliseconds
  })

  const shareUrl =
    typeof window !== "undefined" ? location.origin + location.pathname : ""

  return (
    <div
      className={`absolute inset-0 z-30 transition-opacity transition-faster flex items-center justify-center ${
        shareUri
          ? "opacity-100 pointer-events-auto ease-in"
          : "opacity-0 pointer-events-none ease-out"
      }`}
    >
      <div
        className="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
        onClick={() => setShareUri("")}
      ></div>

      <div className="z-50 w-11/12 mx-auto overflow-y-auto text-base bg-black rounded shadow-lg text-theme-white md:max-w-md">
        <div className="px-6 py-4 text-left modal-content">
          <div className="flex items-center justify-between pb-3">
            <p className="text-lg font-medium">Share </p>
            <button
              className="z-50 cursor-pointer hover:opacity-75"
              onClick={() => setShareUri("")}
            >
              <svg
                className="fill-current text-offwhite"
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 18 18"
              >
                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
              </svg>
            </button>
          </div>
          {/* <p>Modal content can go here</p> */}
          <input
            type="text"
            className="block w-full px-2 py-1 mt-2 rounded bg-theme-darkest"
            ref={clipboard.target}
            value={`${shareUrl}`}
            readOnly
          />
          <div className="flex items-center justify-end pt-4 ">
            <Twitter solid small message={`6lackbox - ${shareUri}`} link={shareUrl} />
            <Facebook solid small message={`6lackbox - ${shareUri}`} link={shareUrl} />
            <Whatsapp solid small message={`6lackbox - ${shareUri}`} link={shareUrl} />
            <button
              className="p-2 px-4 ml-auto mr-2 bg-transparent rounded text-theme-highlight hover:bg-theme-dark hover:text-theme-offwhite"
              onClick={clipboard.copy}
            >
              {clipboard.copied ? "Copied!" : "Copy Link"}
            </button>
            <button
              onClick={() => setShareUri("")}
              className="p-2 px-4 text-white bg-indigo-500 rounded hover:bg-theme-dark"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SharePopUp
