/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import { AnimatePresence } from "framer-motion"
import { FaHome, FaFolder, FaShoppingCart, FaRegEnvelope } from "react-icons/fa"
import { Rnd } from "react-rnd"
import { useQueryParam, StringParam } from "use-query-params"

// import Header from "./header"
// import Image from "./Image"
import useWindowSize from "../hooks/useWindowSize"
import BlackboxLogo from "../images/6blackbox.png"
import PreviewPopUp from "../components/PreviewPopUp"
import SharePopUp from "../components/SharePopUp"
import "../styles/app.css"

import DesktopWallpaperImage from "../images/desktop-wallpaper.jpg"
import BrowserHeaderLeftImage from "../images/browser-header-left.png"
import BrowserHeaderRightImage from "../images/browser-header-right.png"
import BrowserHeaderLoopImage from "../images/browser-header-loop.png"

function isTouchDevice() {
  return (
    !!(
      typeof window !== "undefined" &&
      ("ontouchstart" in window ||
        (window.DocumentTouch &&
          typeof document !== "undefined" &&
          document instanceof window.DocumentTouch))
    ) ||
    !!(
      typeof navigator !== "undefined" &&
      (navigator.maxTouchPoints || navigator.msMaxTouchPoints)
    )
  )
}

const AppWrapper = ({ children }) => {
  const size = useWindowSize()

  if (size.width > 900 && !isTouchDevice()) {
    let browserWidth = size.width - 80
    let browserHeight = size.height - 80
    if (browserWidth > 1100) {
      browserWidth = 1100
    }
    return (
      <div className="desktop">
        <div
          className="fixed inset-0 bg-cover"
          style={{ backgroundImage: `url(${DesktopWallpaperImage})` }}
        ></div>
        <Rnd
          disableDragging={false}
          enableResizing={{ bottomRight: true }}
          bounds="body"
          dragHandleClassName={"handle"}
          resizeHandleClasses={["bottomRight"]}
          minWidth={900}
          minHeight={580}
          style={{
            boxShadow: "0 4px 16px rgba(0,0,0,0.2)",
          }}
          default={{
            x: size.width / 2 - browserWidth / 2,
            y: size.height / 2 - browserHeight / 2,
            width: browserWidth,
            height: browserHeight,
          }}
        >
          <div className="browser">
            <div
              className="browser-header handle"
              //style={{ backgroundImage: `url(${BrowserHeaderLoopImage})` }}
            >
              {/* <img src={BrowserHeaderLeftImage} className="w-auto h-full pointer-events-none" /> */}
              6rowser{" "}
              <div className="flex justify-between ml-auto">
                <div className="w-4 h-4 bg-gray-600 rounded-full"></div>
                <div className="w-4 h-4 ml-2 bg-gray-600 rounded-full"></div>
                <div className="w-4 h-4 ml-2 bg-gray-600 rounded-full"></div>
              </div>
              {/* <img src={BrowserHeaderRightImage} className="w-auto h-full ml-auto pointer-events-none" /> */}
            </div>
            <div className="relative flex flex-col bg-black browser-window site sm:bg-theme-600">
              {children}
            </div>
          </div>
        </Rnd>
      </div>
    )
  }

  return (
    <div className="relative flex flex-col min-h-screen bg-black site sm:bg-theme-600">
      {children}
    </div>
  )
}

const Layout = ({ children, location }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const [sidebarOpen, openSidebar] = useState(false)
  const [shareUri, setShareUri] = useQueryParam("share", StringParam)

  return (
    <AppWrapper>
      <header className="flex flex-shrink-0 sm:hidden">
        <div className="flex-shrink-0 px-6 py-5">
          <h1 className="text-base text-theme-highlight">
            {data.site.siteMetadata.title}
          </h1>
        </div>
        <div className="flex items-center justify-between flex-1 pl-2 pr-6">
          <div className="flex items-center ml-auto">
            <button className="ml-4 text-gray-400 hover:text-gray-200">
              <img src={BlackboxLogo} className="w-10 h-10 fill-current" />
            </button>
          </div>
        </div>
      </header>

      <div className="flex flex-1 overflow-hidden">
        <div
          className={`transform absolute inset-y-0 left-0 z-30 flex flex-col w-60 overflow-y-auto bg-theme-darker sm:static sm:flex sm:translate-x-0 sm:transition-none ${
            sidebarOpen
              ? "translate-x-0 ease-out transition-slow"
              : "-translate-x-full ease-in transition-slow"
          }`}
        >
          <div className="absolute top-0 left-0 pt-3 pl-4 sm:hidden">
            <button
              onClick={() => openSidebar(!sidebarOpen)}
              className="block text-gray-200 hover:text-gray-800"
            >
              <svg className="w-6 h-6" viewBox="0 0 24 24" fill="currentColor">
                <path d="M17.293 18.707a1 1 0 001.414-1.414L13.414 12l5.293-5.293a1 1 0 00-1.414-1.414L12 10.586 6.707 5.293a1 1 0 00-1.414 1.414L10.586 12l-5.293 5.293a1 1 0 101.414 1.414L12 13.414l5.293 5.293z" />
              </svg>
            </button>
          </div>
          <header className="px-10 mt-8 text-theme-highlight">
            <img src={BlackboxLogo} className="w-10 h-10" />
          </header>
          <nav className="flex flex-col flex-1 mt-16 sm:mt-3">
            <div className="px-10 mt-0">
              {/* <h2 className="mt-8 text-xs font-semibold tracking-wide text-gray-600 uppercase">
                Folders
              </h2> */}
              <div className="mt-4 text-theme-white">
                <Link
                  to="/"
                  className="block text-base hover:opacity-75"
                  activeClassName="text-theme-highlight"
                >
                  Home
                </Link>
                <Link
                  to="/"
                  className="block mt-4 text-base hover:opacity-75"
                  activeClassName="text-theme-highlight"
                >
                  Files
                </Link>
                <button
                  type="button"
                  className="block mt-4 text-base hover:opacity-75"
                  onClick={() => setShareUri("/", "pushIn")}
                >
                  Share
                </button>
              </div>
            </div>
            <div className="relative flex items-center justify-between px-4 mt-auto cursor-pointer hover:bg-theme-dark group ">
              <hr className="absolute top-0 left-0 right-0 mx-4 border-t border-theme-darkest" />
              <div className="py-4 " onClick={() => setShareUri("/", "pushIn")}>
                <a className="block text-sm font-medium text-theme-offwhite">
                  Personal
                </a>
                <a className="block text-xs font-medium text-theme-white">
                  Only you
                </a>
              </div>
              <svg
                className="w-6 h-6 text-theme-light group-hover:text-theme-offwhite"
                viewBox="0 0 24 24"
              >
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <g
                    id="form"
                    transform="translate(-160.000000, -32.000000)"
                    fill="currentColor"
                  >
                    <g
                      id="form-switcher"
                      transform="translate(160.000000, 32.000000)"
                    >
                      <path
                        d="M10.5,9 L8.26000214,9 L12.0100021,4 L15.7600021,9 L13.5,9 L12,7 L10.5,9 Z"
                        id="Combined-Shape"
                      ></path>
                      <path
                        d="M10.5,20 L8.26000214,20 L12.0100021,15 L15.7600021,20 L13.5,20 L12,18 L10.5,20 Z"
                        id="Combined-Shape"
                        transform="translate(12.010002, 17.500000) scale(1, -1) translate(-12.010002, -17.500000) "
                      ></path>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
          </nav>
        </div>
        <main className="flex flex-1 bg-black sm:bg-theme-darkest">
          <AnimatePresence exitBeforeEnter>{children}</AnimatePresence>
        </main>
      </div>
      <footer className="fixed bottom-0 flex w-full bg-black sm:hidden">
        <div className="flex items-center justify-between flex-1 pl-2 pr-6">
          <div className="flex items-center text-theme-white">
            <Link
              className="flex flex-col items-center justify-center p-3 hover:text-gray-200"
              to="/"
              activeClassName="text-theme-highlight"
            >
              <FaHome className="w-6 h-6 mb-1 fill-current" />
              <span className="text-xs">Home</span>
            </Link>
            <Link
              className="flex flex-col items-center justify-center p-3 ml-5 hover:text-gray-200"
              to="/files"
              activeClassName="text-theme-highlight"
              partiallyActive={true}
            >
              <FaFolder className="w-6 h-6 mb-1 fill-current" />
              <span className="text-xs">Files</span>
            </Link>
            <a
              className="flex flex-col items-center justify-center p-3 ml-5 hover:text-gray-200"
              href="http://www.eastatlantaloveletter.com/signup"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaRegEnvelope className="w-6 h-6 mb-1 fill-current" />
              <span className="text-xs">Sign Up</span>
            </a>
            <a
              className="flex flex-col items-center justify-center p-3 ml-5 hover:text-gray-200"
              href="https://6lackmarket.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaShoppingCart className="w-6 h-6 mb-1 fill-current" />
              <span className="text-xs">Shop</span>
            </a>
          </div>
        </div>
      </footer>
      <PreviewPopUp />
      <SharePopUp location={location} />
    </AppWrapper>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
