// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-files-js": () => import("./../src/pages/files.js" /* webpackChunkName: "component---src-pages-files-js" */),
  "component---src-pages-index-old-js": () => import("./../src/pages/index_old.js" /* webpackChunkName: "component---src-pages-index-old-js" */),
  "component---src-templates-files-page-js": () => import("./../src/templates/files-page.js" /* webpackChunkName: "component---src-templates-files-page-js" */)
}

