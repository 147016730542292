import React, { useState, useEffect } from "react"
import WaveSurfer from "wavesurfer.js"
import { FaPlay, FaPause } from "react-icons/fa"


function display (seconds) {
  const format = val => `0${Math.floor(val)}`.slice(-2)
  // const hours = seconds / 3600
  const minutes = (seconds % 3600) / 60

  return [minutes, seconds % 60].map(format).join(':')
}

const AudioPlayer = ({ url, className }) => {
  let [waveSurfer, setWaveSurfer] = useState(null)
  let [duration, setDuration] = useState(0)
  let [position, setPosition] = useState(0)
  let [loading, setLoading] = useState(true)
  let [playerState, setPlayerState] = useState(null)

  useEffect(() => {
    let newWaveSurfer = WaveSurfer.create({
      barWidth: 2,
      barRadius: 0,
      cursorWidth: 0,
      height: 1200,
      barGap: 2,
      hideScrollbar: true,
      width: "100%",
      normalize: true,
      responsive: true,
      partialRender: true,
      barHeight: 0,
      container: "#waveform",
      // backend: "MediaElement",
      progressColor: "#4a74a5",
      waveColor: "#ccc",
      cursorColor: "#4a74a5",
    })

    //newWaveSurfer.load(document.querySelector("#song"));
    newWaveSurfer.load(
      url
    )

    newWaveSurfer.on("audioprocess", (positionPercentage) => {
      setPosition(positionPercentage)
    })

    newWaveSurfer.on("ready", () => {
      
      setLoading(false)
      newWaveSurfer.play();
      setTimeout(() => setDuration(newWaveSurfer.getDuration()), 500);
    })

    newWaveSurfer.on("pause", (e) => {
      setPlayerState('paused')
    })

    newWaveSurfer.on("play", (e) => {
      setPlayerState('playing')
    })

    setWaveSurfer(newWaveSurfer)

    return () => {
      newWaveSurfer.destroy()
      // setWaveSurfer(null);
      // setPosition(0)
      // setLoading(false)
      // setLoadingPercentage(0)
      // setPlayerState("stopped")
      // setDuration(0)
    }


  }, [])

  return (
    <div className={`relative flex flex-col justify-end ${className} p-4 bg-white`}>
      <div id="waveform" className="block w-full mb-1 overflow-hidden" style={{height: 600}} />
      <div className="relative w-full h-1 bg-theme-mid">
        <div className="w-full h-1 bg-theme-offwhite" style={{transform: `scaleX(${(position*100/duration) / 100})`, transformOrigin: '0% 50%'}}></div>
      </div>
      <div className="flex items-center w-full p-3 py-2 bg-theme-darker text-theme-offwhite">
        <button
          onClick={() => {
            playerState === "paused" ? waveSurfer.play() : waveSurfer.pause()
          }}
          className="p-3"
        >
          { playerState !== "playing" ? <FaPlay className="w-4 h-4 current-fill" /> : <FaPause className="w-4 h-4 current-fill" />  }
        </button>
        <div className="ml-4 text-xs leading-snug">
          { display(position) } / { display(duration) }
        </div>
      </div>
      {loading ? <div className="absolute inset-0 flex items-center justify-center bg-theme-white"><div className="text-black app-spinner">Loading</div></div> : null}
    </div>
  )
}

export default AudioPlayer
